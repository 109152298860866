* {
    box-sizing: border-box;
    background-color: rgb(244, 244, 244);
    color: #333;
    font-size: 10px;
}

.App {
    margin: 0;
    padding: 0;
}

.container {
    margin: 0 auto;
    max-width: 1000px;
    padding: 40px;
}

.title {
    font-size: 4.4rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.card {
    margin-bottom: 1rem;
    display: inline-flex;
    flex-wrap: wrap;
}

.card a {
    width: 100%;
}

.card--image {
    flex: 100%;
    margin-top: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 100%;
}

.card-list {
    column-count: 3;
}

.card-info {
    padding: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #333;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.card-info div {
    font-size: inherit;
}

.card-info a {
    font-size: inherit;
    text-decoration: none;
}

.card-full {
    display: flex;
    flex-wrap: wrap;
}

.card-full a {
    font-size: 2rem;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .card-list {
        column-count: 1;
    }
}